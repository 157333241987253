import request from "../utils/request";
//新建新闻活动分类
export const createType = data => {
  return request({
    method: 'POST',
    url: '/system/news/addNewsCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除新闻活动分类
export const delType = data => {
  return request({
    method: 'POST',
    url: '/system/news/deleteNewsCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取新闻活动分类详情
export const getTypeDetail = data => {
  return request({
    method: 'POST',
    url: '/system/news/getDetailNewsCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取新闻活动分类列表
export const getTypeList = data => {
  return request({
    method: 'POST',
    url: '/system/news/getNewsCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改新闻活动类型
export const changeType = data => {
  return request({
    method: 'POST',
    url: '/system/news/updateNewsCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
// ========分割线=======新闻活动内容
//新建内容
export const createCont = data => {
  return request({
    method: 'POST',
    url: '/system/news/addNews',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除内容
export const delCont = data => {
  return request({
    method: 'POST',
    url: '/system/news/deleteNews',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取内容详情
export const getContDetail = data => {
  return request({
    method: 'POST',
    url: '/system/news/getDetailNews',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取内容列表
export const getContList = data => {
  return request({
    method: 'POST',
    url: '/system/news/getNews',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改内容
export const changeCont = data => {
  return request({
    method: 'POST',
    url: '/system/news/updateNews',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
